/*  font family */
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Light.ttf') format('opentype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Regular.ttf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Medium.ttf') format('opentype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-SemiBold.ttf') format('opentype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Bold.ttf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

body {
  overflow: hidden;
}
.mt15 {
  margin-top: 15px;
}

/* Button Disabled CSS */

button:disabled,
button[disabled] {
  border: 1px solid #979797 !important;
  color: #dfdfdf !important;
  background-color: transparent !important;
}

.checkbox-field {
  position: relative;
  padding-left: 24px;
  font-weight: 500;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 16px;
}
.checkbox-field input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox-span-rem {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 2;
  width: 16px;
  height: 16px;
  margin-top: 0px;
  margin-left: 0px;
  border: 1px solid #262626;
  border-radius: 3px;
  background-color: #0e0e0e;
}
.checkbox-field:hover input ~ .checkbox-span-rem {
  border: 1px solid #39d377;
}
.checkbox-field input:checked ~ .checkbox-span-rem {
  border: 1px solid #39d377;
  background-color: transparent;
  background-image: url('https://uploads-ssl.webflow.com/5ed48abcec5df2a9e7fd9e96/5ef4974c7d050d2537fa83da_checkmark.svg');
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: center center;
}
.checkbox-field:after {
  content: '';
  position: absolute;
  display: none;
}
.checkbox-field input:checked ~ .checkbox-span-rem:after {
  display: block;
}

/* Checkbox Style */
.checkbox-block {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox-block input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox-span {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 2;
  width: auto;
  height: auto;
  margin-top: 0px;
  margin-left: 0px;
  border: 1px solid #121212;
  background-color: transparent;
  border-radius: 3px;
}
.checkbox-block:hover input ~ .checkbox-span {
  border: 1px solid #39d377;
}
.checkbox-block input:checked ~ .checkbox-span {
  border: 1px solid #39d377;
  background-color: transparent;
}
.checkbox-span:after {
  content: '';
  position: absolute;
  display: none;
}
.checkbox-block input:checked ~ .checkbox-span:after {
  display: block;
}

/* Radio Style */
.radio-field {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0;
}

/* Hide the browser's default radio button */
.radio-field input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radio-span {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  background-color: #000;
  border: 2px solid #484848;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-field:hover input ~ .radio-span {
  background-color: #000;
}

/* When the radio button is checked, add a blue background */
.radio-field input:checked ~ .radio-span {
  background-color: #000;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-span:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-field input:checked ~ .radio-span:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-field .radio-span:after {
  top: 3px;
  left: 3px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #39d377;
}

/* slider */
.w-slider-dot {
  width: 10px;
  height: 10px;
  border: 2px solid #ffffff;
  background-color: transparent;
}
.w-slider-dot.w-active {
  background-color: #fff;
}

/* Model Hide and Show */

.d-block {
  display: block;
}

/* Alert Message css */

.alert-box {
  padding: 0px 30px;
}
.alert-message {
  color: #ce0d0d;
  margin-bottom: 18px;
  font-size: 12px;
}
.success-message {
  color: #39d377;
  margin-bottom: 18px;
  font-size: 11px;
}

/* Dashboard */

.content-wrapper {
  height: calc(100vh - 61px);
}
button:focus {
  outline: none;
}

/* Pricing Page css */

.pricing-content {
  height: calc(100vh - 100px);
}

.payment-model-inner form {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .pricing-content {
    height: calc(100vh - 73px);
  }
}

/* Button Loadind */

/* Dot Animation
  ==============================*/

.mc-loading.form-btn-block {
  position: relative;
}
.mc-loading.form-btn-block:before {
  position: absolute;
  top: 50%;
  left: calc(50% - 2px);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: '';
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: white;
  z-index: 2;
  margin-top: 4px;
  -webkit-animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate bounceDot;
  animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate bounceDot;
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}
.mc-loading.form-btn-block button,
.mc-loading.form-btn-block .mc-AinmateSubBox {
  color: transparent;
  cursor: wait;
}
.mc-loading.form-btn-block button:before,
.mc-loading.form-btn-block .mc-AinmateSubBox:before {
  position: absolute;
  top: 50%;
  left: calc(50% - 2px);
  opacity: 1;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: '';
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: white;
  z-index: 2;
  margin-top: 4px;
  -webkit-animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate bounceDot;
  animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate bounceDot;
  margin-left: -10px;
}
.mc-loading.form-btn-block button:after,
.mc-loading.form-btn-block .mc-AinmateSubBox:after {
  position: absolute;
  top: 50%;
  left: calc(50% - 2px);
  opacity: 1;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: '';
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: white;
  z-index: 2;
  margin-top: 4px;
  -webkit-animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate bounceDot;
  animation: 0.45s cubic-bezier(0, 0, 0.15, 1) infinite alternate bounceDot;
  margin-left: 10px;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
@-webkit-keyframes bounceDot {
  from {
    -webkit-transform: translateY(0) scaleY(0.8);
    transform: translateY(0) scaleY(0.8);
  }
  to {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
@keyframes bounceDot {
  from {
    -webkit-transform: translateY(0) scaleY(0.8);
    transform: translateY(0) scaleY(0.8);
  }
  to {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@media screen and (max-width: 991px) {
  .content-wrapper {
    height: calc(100vh - 0px);
    padding-top: 72px;
  }
  .navbar-dashboard {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
  }
}

/* CARD'S Style */
.loading-text {
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  padding: 16px;
  margin: 0px;
}
.card-name-box {
  background-color: rgba(255, 255, 255, 0.15);
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-style: italic;
  margin-right: 8px;
}
.payment-model-top.nopadding {
  padding: 0px;
}
.saved-card-item {
  padding: 16px;
  border-bottom: 1px solid #262626;
}
.saved-card-item-last {
  padding: 16px 16px 0px 16px;
}
.saved-card-item-new {
  padding: 0px 16px 16px 16px;
  border-bottom: 1px solid #262626;
}
.saved-card-item-new .StripeElement {
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 2px solid #262626;
  margin-bottom: 16px;
}
.btn-with-icon {
  width: 32px;
  height: 32px;
  padding: 0;
  line-height: 0;
  background-color: transparent;
  border-radius: 100%;
  margin: 0px 8px;
}

/* Header Style */
.nav-link.w-nav-link.ctm {
  background-color: transparent;
  background-image: none;
  border: 0;
  box-shadow: none;
  padding: 2px;
}

.nav-top-menubar {
  margin-left: auto;
}

.nav-top-menubar .nav-link.w-nav-link.ctm {
  padding: 8px 16px;
  border: 1px solid #39d377;
  color: #39d377;
  border-radius: 22px;
}

.dropdown-list-wrap {
  display: flex;
  margin: 0px auto;
  padding: 10px 22px;
  border-radius: 50px;
  background-color: #121212;
}

.dropdown-list-wrap .dropdown-list.w--open {
  max-width: 122px;
  min-width: 122px;
}

.dropdown-list-wrap .nav-link {
  padding: 8px 16px;
  margin: 0px;
  white-space: nowrap;
  display: block;
}

.dropdown-list-point {
  background-color: #333333;
  border-radius: 8px;
  width: 6px;
  height: 6px;
  margin: 5px 16px;
}

.dropdown-list-wrap {
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
}

.w-dropdown-link.w--current {
  color: #39d377;
}

.language-select {
  display: block;
}

.language-select .dropdown-toggle {
  position: relative;
  height: auto;
  margin-bottom: 0px;
  padding: 12px 40px 12px 16px;
  border: 1px solid transparent;
  border-radius: 3px;
  background-color: #121212;
  color: #fff;
  line-height: 17px;
  font-weight: 500;
}

.language-select .profile-arrow {
  font-size: 18px;
  right: 10px;
}

.language-select .dropdown-list a {
  display: block;
  padding: 8px 16px;
  margin: 0px;
}

.profile-block {
  margin: 0px 12px 0px 0px;
}

.language-box {
  background-image: url('./../images/select-arrow.svg');
  background-repeat: no-repeat;
  background-position: right center;
  background-color: #000000;
  border: 1px solid transparent;
  border-radius: 32px;
  font-size: 14px;
  font-weight: 700;
  color: #b5b8bf;
  padding: 6px 18px 6px 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.language-box:focus {
  outline: 0;
}

.language-block a {
  color: #b5b8bf;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: all 100ms ease;
}
.language-block a:hover {
  border-bottom: 1px solid #b5b8bf;
}

@media screen and (max-width: 767px) {
  .navbar-dashboard {
    height: 100px;
  }
  .content-wrapper {
    padding-top: 116px;
  }
  .profile-block {
    margin: 0px 8px 0px auto;
  }
  .nav-top-menubar {
    position: absolute;
    left: 8px;
    bottom: 12px;
  }
  .dropdown-list-wrap {
    position: absolute;
    bottom: 12px;
    right: 0;
    margin: 0px;
    transform: none;
    top: auto;
    left: auto;
    padding: 10px 12px;
  }
  .dropdown-list-point {
    margin: 5px 6px;
  }
  .dropdown-list-wrap .dropdown-list.w--open {
    max-width: 110px;
    min-width: 110px;
  }
  .nav-inner .language-box {
    background-size: 11px;
    font-size: 12px;
    padding: 4px 12px 4px 0px;
  }
}

/* profile page Css */

.editprofile-wrapper {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
}
.editprofile-wrapper .pricing-head-block {
  margin-bottom: 25px;
}
.editprofile-block .form-row {
  flex-direction: column;
}
.editprofile-block .form-row .form-col {
  margin-bottom: 20px;
}
.editprofile-block .language-box {
  background-position: right 12px center;
  padding: 12px 40px 12px 16px;
  border: 1px solid transparent;
  border-radius: 3px;
  background-color: #121212;
  color: #fff;
  line-height: 17px;
  font-weight: 500;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .editprofile-wrapper {
    max-width: 100%;
  }
  .editprofile-wrapper .pricing-head-block {
    margin-bottom: 18px;
  }
}

.mg-auto {
  margin: 0px auto;
}